import { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import Typed from "./Typed";
// import resume from "./Shivakumarmangina_Blockchain_Fullstack_developer.pdf";
import "./App.css";

const App = ({ ReactGA }) => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/" });
  }, [ReactGA]);

  return (
    <>
      <section className="w-full min-h-screen antialiased bg-white text-[#272341] flex flex-col justify-center items-center">
        <div className="container text-left w-4/5">
          <Fade duration={1000} delay={500} distance="30px">
            <h1 className="text-6xl font-bold  leading-[5rem]">
              Hi, I am &nbsp;
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-blue-500 to-purple-500">
                Shiva Kumar Mangina
              </span>
              <br />
              <Typed
                strings={[
                  "I'm a React Developer",
                  "I'm a Node Developer",
                  "I'm a Solidity Developer",
                  "I'm a JavaScript Developer",
                ]}
                typeSpeed={40}
                backSpeed={50}
                loop
              />
            </h1>
          </Fade>
          <Fade left={true} duration={1000} delay={1000} distance="30px">
            <div className="flex flex-wrap">
              <p className="mt-10  hover:cursor-pointer md:w-1/4 sm:w-1/2">
                <span className="font-bold text-2xl text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-purple-700 font-['Courier_Prime'] hover:text-[#272341] ">
                  <a href="https://www.canva.com/design/DAFJ7_zySQc/7sU_IpL3tPL26YCk1z8ENw/view?website#2:hey-i-am-shiva.-i-am-a-results-oriented-and-innovative-software-engineer-with-experience-in-web-development-and-blockchain.-i-m-highly-effective-at-developing-new-programs-and-fixing-problems-with-existing-systems." rel="noreferrer" target={"_blank"}>
                    <span
                      onClickCapture={() =>
                        ReactGA.event({
                          category: "Home",
                          action: "Link Click",
                          label: "Resume",
                        })
                      }
                      className="mx-5 my-5 "
                    >
                      \Resume
                    </span>
                  </a>
                </span>
              </p>
              <p className="mt-10 hover:cursor-pointer md:w-1/4 sm:w-1/2">
                <span className="font-bold text-2xl text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-pink-400 font-['Courier_Prime'] hover:text-[#272341] ">
                  <a
                    href="https://www.youtube.com/channel/UCpGqOxTAIXbUgksloktMSgQ"
                    rel="noreferrer"
                    target={"_blank"}
                    onClickCapture={() =>
                      ReactGA.event({
                        category: "Home",
                        action: "Link Click",
                        label: "Youtube",
                      })
                    }
                  >
                    <span className="mx-5 my-5">\Youtube</span>
                  </a>
                </span>
              </p>
              <p className="mt-10 hover:cursor-pointer md:w-1/4 sm:w-1/2">
                <span className="font-bold text-2xl text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-purple-700 font-['Courier_Prime'] hover:text-[#272341] ">
                  <Link
                    to="about-me"
                    smooth={true.toString()}
                    duration={1000}
                    onClickCapture={() =>
                      ReactGA.event({
                        category: "Home",
                        action: "Link Click",
                        label: "About Me",
                      })
                    }
                  >
                    <span className="mx-5 my-5">\About-me</span>
                  </Link>
                </span>
              </p>
              <p className="mt-10 hover:cursor-pointer md:w-1/4 sm:w-1/2">
                <span className="font-bold text-2xl text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-green-500 font-['Courier_Prime'] hover:text-[#272341]">
                  <a
                    href="https://medium.com/@shiva2nani.mangina"
                    rel="noreferrer"
                    target={"_blank"}
                    onClickCapture={() =>
                      ReactGA.event({
                        category: "Home",
                        action: "Link Click",
                        label: "Blog",
                      })
                    }
                  >
                    <span className="mx-5 my-5">\Blog</span>
                  </a>
                </span>
              </p>
            </div>
          </Fade>
        </div>
      </section>
    </>
  );
};

export default App;
